
































































































































































































































import Vue from 'vue'
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BLink,
  BButton,
  BSpinner,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { filters } from '@core/mixins/filters'
import { loginAs } from '@core/mixins/loginAs'
import pushStateFiltersParams from '@/helpers/pushStateFiltersParams'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import { appStudentsList } from '@/store/modules/app-students/app-students-list'
import { permissionSubjects } from '@/libs/acl/constants'
import ConfirmationModal from '@/views/components/confirmation/ConfirmationModal.vue'
import VisibleColumnsDropdown from '@/views/components/visible-columns-dropdown/VisibleColumnsDropdown.vue'
import defaultColumns from '@/views/admin/students/helpers/allStudentsColumns'

let debounceTimeout: ReturnType<typeof setTimeout> = setTimeout(() => '', 1000)

export default Vue.extend({
  name: 'AllStudentsList',
  components: {
    VisibleColumnsDropdown,
    ConfirmationModal,
    BSpinner,
    BButton,
    BAvatar,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,

    vSelect,
    TableSpinner,
  },
  mixins: [filters, loginAs],
  data() {
    return {
      currentRouteName: this.$router.currentRoute.name,
      deletableUser: {
        id: null,
        full_name: null,
      },
      permissionSubjects,
    }
  },
  computed: {
    tableColumns: {
      get() {
        return appStudentsList.getters.getTableColumns
      },
      set(val: number) {
        appStudentsList.mutations.SET_TABLE_COLUMNS(val)
      },
    },
    visibleTableColumns() {
      return appStudentsList.getters.getVisibleTableColumns
    },
    perPage() {
      return appStudentsList.getters.getPerPage
    },
    totalItems() {
      return appStudentsList.getters.getTotalItems
    },
    currentPage: {
      get() {
        return appStudentsList.getters.getCurrentPage
      },
      set(val: number) {
        appStudentsList.mutations.SET_CURRENT_PAGE(val)
      },
    },
    perPageOptions() {
      return appStudentsList.getters.getPerPageOptions
    },
    searchQuery: {
      get() {
        return appStudentsList.getters.getSearchQuery
      },
      set(val: string) {
        appStudentsList.mutations.SET_SEARCH_QUERY(val)
      },
    },
    sortBy: {
      get() {
        return appStudentsList.getters.getSortBy
      },
      set(val: string) {
        appStudentsList.mutations.SET_SORT_BY(val)
      },
    },
    isSortDirDesc: {
      get() {
        return appStudentsList.getters.getIsSortDirDesc
      },
      set(val: boolean) {
        appStudentsList.mutations.SET_IS_SORT_DESC(val)
      },
    },
    isLoading() {
      return appStudentsList.getters.getIsLoading
    },
    dataMeta() {
      return appStudentsList.getters.getDataMeta
    },
  },
  watch: {
    $route() {
      if (!this.$route.query.currentPage) {
        this.setFilterParams()
      }
      this.updateTable()
    },
    currentPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'currentPage', val)
      }
    },
    perPage(val, prevVal) {
      if (val !== prevVal) {
        pushStateFiltersParams(this, this.currentRouteName, 'perPage', val)
      }
    },
    searchQuery(val, prevVal) {
      if (val !== prevVal) {
        clearTimeout(debounceTimeout)
        debounceTimeout = setTimeout(() => {
          pushStateFiltersParams(this, this.currentRouteName, 'searchQuery', val)
        }, 250)
      }
    },
  },
  created() {
    this.setStudentsViewingColumnsSettings()

    this.setFilterParams()
    // Update filters
    window.onpopstate = () => {
      this.setFilterParams()
    }
  },
  methods: {
    setStudentsViewingColumnsSettings() {
      const allStudentsTableColumns = localStorage.getItem('allStudentsTableColumns') ? localStorage.getItem('allStudentsTableColumns') : null
      const localStorageColumns = allStudentsTableColumns != null ? JSON.parse(allStudentsTableColumns) : []

      if (localStorageColumns.length > 0) {
        const localStorageColumnsKeys = localStorageColumns.map((col: any) => col.key)
        const defaultTableColumnsKeys = defaultColumns.map((col: any) => col.key)
        const differentColumns = defaultTableColumnsKeys
          .filter(key => !localStorageColumnsKeys.includes(key))
          .concat(localStorageColumnsKeys.filter((key: string) => !defaultTableColumnsKeys.includes(key)))

        const localStorageColumnsNames = localStorageColumns.map((col: any) => col.label)
        const defaultTableColumnsNames = defaultColumns.map((col: any) => col.label)
        const differentColumnsNames = defaultTableColumnsNames
          .filter(name => !localStorageColumnsNames.includes(name))
          .concat(localStorageColumnsNames.filter((name: string) => !defaultTableColumnsNames.includes(name)))

        const localStorageColumnsSortableSetting = localStorageColumns.map((col: any) => col.label + (col.sortable ? '1' : '0'))
        const defaultTableColumnsSortableSetting = defaultColumns.map((col: any) => col.label + (col.sortable ? '1' : '0'))

        const differentColumnsSortableSetting = defaultTableColumnsSortableSetting
          .filter((name: string) => !localStorageColumnsSortableSetting.includes(name))

        if (differentColumns.length > 0 || differentColumnsNames.length > 0 || differentColumnsSortableSetting.length > 0) {
          this.tableColumns = JSON.parse(JSON.stringify(defaultColumns))
        } else {
          this.tableColumns = JSON.parse(JSON.stringify(localStorageColumns))
        }
        return
      }
      this.tableColumns = JSON.parse(JSON.stringify(defaultColumns))
    },
    updatePerPage(val: number) {
      appStudentsList.mutations.SET_PER_PAGE(val)
    },
    async fetchAllStudentsList() {
      return appStudentsList.actions.fetchAllStudentsList()
    },
    async deleteItem() {
      if (this.deletableUser.id != null) {
        await appStudentsList.actions.deleteStudent(this.deletableUser.id)
        this.closeDeleteConfirmationModal()
        this.updateTable()
      }
    },
    setFilterParams() {
      appStudentsList.mutations.SET_FILTER_PARAMS()
    },
    async exportStudentsCSV() {
      await appStudentsList.actions.exportStudentsCSV()
    },
    clearFilters() {
      appStudentsList.mutations.CLEAR_FILTERS()
    },
    saveVisibleColumns(columns: any, sortByField: string, isSortByDesc: boolean) {
      this.tableColumns = JSON.parse(JSON.stringify(columns))
      this.sortBy = sortByField
      this.isSortDirDesc = isSortByDesc
    },
    updateTable() {
      (this.$refs.refStudentsListTable as BTable).refresh()
    },
    showDeleteConfirmationModal(user: any) {
      this.deletableUser.id = user.id
      this.deletableUser.full_name = user.full_name
      this.$nextTick(() => {
        this.$bvModal.show('confirmation-delete-student')
      })
    },
    closeDeleteConfirmationModal() {
      this.deletableUser.id = null
      this.deletableUser.full_name = null
      this.$bvModal.hide('confirmation-delete-student')
    },
  },
})
